<template>
  <div class="contactus-4">
    <gmap-map
      id="map"
      class="map"
      :center="center"
      :zoom="13"
      :options="options"
      map-type-id="terrain"
    >
      <gmap-marker :position="center"> </gmap-marker>
    </gmap-map>
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <h1 class="title text-white"></h1>
          <!-- <h4 class="description text-white">
            Do you need more information? Please contact us to find more about
            our products and services.
          </h4> -->
        </div>
        <div id="contact-us" class="col-md-12 m-auto">
          <div class="card card-contact card-raised">
            <div class="row">
              <div class="col-lg-8 col-md-7 pr-md-0">
                <form role="form" class="p-3" id="contact-form" method="post">
                  <div class="card-header">
                    <h4 class="card-title">立即洽詢</h4>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>姓名</label>
                          <base-input
                            placeholder="請輸入姓名"
                            addonLeftIcon="ni ni-circle-08"
                          ></base-input>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>電話</label>
                          <base-input
                            placeholder="請輸入電話"
                            addonLeftIcon="ni ni-collection"
                          ></base-input>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Email</label>
                      <base-input
                        placeholder="請輸入Email"
                        addonLeftIcon="ni ni-email-83"
                      ></base-input>
                    </div>
                    <div class="form-group">
                      <label>訊息</label>
                      <textarea
                        name="message"
                        class="form-control"
                        id="message"
                        rows="6"
                      ></textarea>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <!-- <base-checkbox class="mb-3"
                          >I'm not a robot</base-checkbox
                        > -->
                      </div>
                      <div class="col-md-6">
                        <base-button
                          nativeType="submit"
                          type="info"
                          class="pull-right"
                          >送出</base-button
                        >
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-lg-4 col-md-5 pl-md-0">
                <div class="info text-left bg-info">
                  <h4 class="card-title text-white">聯絡資訊</h4>
                  <div class="info info-horizontal mt-lg-5">
                    <div
                      class="icon icon-shape bg-white rounded-circle text-info"
                    >
                      <i class="ni ni-square-pin"></i>
                    </div>
                    <div class="description">
                      <p class="info-title text-white mt-2">
                        新北市板橋區觀光街3巷7號3樓
                      </p>
                    </div>
                  </div>
                  <div class="info info-horizontal">
                    <div
                      class="icon icon-shape bg-white rounded-circle text-info"
                    >
                      <i class="ni ni-mobile-button"></i>
                    </div>
                    <div class="description">
                      <p class="info-title text-white mt-2">
                        0973313312
                      </p>
                    </div>
                  </div>
                  <div class="info info-horizontal">
                    <div
                      class="icon icon-shape bg-white rounded-circle text-info"
                    >
                      <i class="ni ni-email-83"></i>
                    </div>
                    <div class="description">
                      <p class="info-title text-white mt-2">
                        kcc@kuoch.in
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { API_KEY } from '@/constants';
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
  load: {
    key: API_KEY,
  },
});

export default {
  data() {
    return {
      center: {
        lat: 25.0163714,
        lng: 121.4687431,
      },
      options: {
        styles: [
          {
            featureType: 'administrative',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#444444',
              },
            ],
          },
          {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
              {
                color: '#f2f2f2',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'all',
            stylers: [
              {
                saturation: -100,
              },
              {
                lightness: 45,
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'all',
            stylers: [
              {
                visibility: 'simplified',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'transit',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'all',
            stylers: [
              {
                color: '#C5CBF5',
              },
              {
                visibility: 'on',
              },
            ],
          },
        ],
      },
    };
  },
};
</script>
<style scoped>
.contactus-4 .map:after {
  background: unset;
}
</style>
