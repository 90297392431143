<template>
  <div class="section features-3">
    <div class="container">
      <div class="row text-center justify-content-center">
        <div class="col-lg-8">
          <h3 class="display-3 text-white">跟我們聊聊吧</h3>
          <div class="item button-jittery">
            <button @click="goTo">點我立即預約</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goTo() {
      document.getElementById('contact-us').scrollIntoView(true);
    },
  },
};
</script>
<style scoped>
button {
  background: transparent;
  color: #fff;
  border: 3px solid #fff;
  border-radius: 50px;
  padding: 0.8rem 2rem;
  font: 24px 'Margarine', sans-serif;
  outline: none;
  cursor: pointer;
  position: relative;
  transition: 0.2s ease-in-out;
  letter-spacing: 2px;
  margin-top: 1em;
}

.item {
  align-items: center;
  justify-content: center;
}

.button-jittery button {
  animation: jittery 4s infinite;
}

.button-jittery button:hover {
  animation: heartbeat 0.2s infinite;
}

@keyframes jittery {
  5%,
  50% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  15% {
    transform: scale(1.15);
  }
  20% {
    transform: scale(1.15) rotate(-5deg);
  }
  25% {
    transform: scale(1.15) rotate(5deg);
  }
  30% {
    transform: scale(1.15) rotate(-3deg);
  }
  35% {
    transform: scale(1.15) rotate(2deg);
  }
  40% {
    transform: scale(1.15) rotate(0);
  }
}
@keyframes heartbeat {
  50% {
    transform: scale(1.1);
  }
}
</style>