<template>
  <div class="wrapper">
    <div class="sea">
      <svg viewBox="0 0 1920 1080">
        <text text-anchor="middle" x="50%" y="50%">過晉的一頁式</text>
      </svg>
      <div class="cloud" id="cA">
        <span id="sA"></span><span id="sB"></span>
      </div>
      <div class="cloud" id="cB">
        <span id="sA"></span><span id="sB"></span>
      </div>
      <div class="cloud" id="cC">
        <span id="sA"></span><span id="sB"></span>
      </div>
      <div class="cloud" id="cD">
        <span id="sA"></span><span id="sB"></span>
      </div>

      <div id="foot"></div>
      <div class="boat-two"><span id="flag"></span></div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 text-center mx-auto">
            <h3 class="display-3">什麼是一頁式網站？</h3>
            <p class="lead">
              一頁式網站將所有的內容都放在同一個頁面，讓使用者專注在當前的頁面，引導使用者不斷的往下滑動完成網站想要達成的目標
            </p>
          </div>
        </div>
        <object
          type="image/svg+xml"
          data="img/example/page_slide.svg"
          width="100%"
        />
      </div>
    </div>
    <OnePageAdvantage></OnePageAdvantage>
    <CtaJittery></CtaJittery>
    <EventFeature></EventFeature>
    <div class="section cta">
      <div class="container">
        <div class="row">
          <div class="col-md-8 text-center mx-auto">
            <h3 class="display-3">各種 CTA</h3>
            <p class="lead">
              顏色、大小、文字和位置是 CTA (Call To Action)
              最重要的四個元素，我們幫您設計優秀的 CTA
              ，刺激使用者的想像，在不知不覺中完成轉換
            </p>
          </div>
        </div>
      </div>
    </div>
    <CallToAction></CallToAction>
    <div id="slide" class="container">
      <div class="row align-items-center text-left">
        <div class="col-lg-8 col-12">
          <Boating></Boating>
        </div>
        <div class="col-md-4">
          <h3 class="display-3 mt-3">大家都在滑手機</h3>
          <p class="lead mt-0">
            現今手機上網人口已經超越桌機，一頁式網站一路滑到底的特性非常適合在手機上瀏覽
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="register-page">
      <div class="page-header bg-default">
        <div
          class="page-header-image"
          style="background-image: url('img/ill/register_bg.png')"
        ></div>
        <div
          class="container"
          v-bind:class="{ 'right-panel-active': isActive }"
          id="container"
        >
          <div class="form-container sign-up-container">
            <form action="javascript:;">
              <h2>白天</h2>

              <span class="text-default mb-4">要約晚上的話點隔壁喔</span>
              <div class="form-group mb-0">
                <base-input
                  alternative
                  placeholder="Name"
                  addonLeftIcon="ni ni-circle-08"
                ></base-input>
              </div>
              <div class="form-group mb-0">
                <base-input
                  alternative
                  placeholder="電話"
                  addonLeftIcon="ni ni-email-83"
                ></base-input>
              </div>
              <div class="form-group">
                <base-input
                  alternative
                  placeholder="Email"
                  addonLeftIcon="ni ni-email-83"
                ></base-input>
              </div>
              <base-button type="primary">預約</base-button>
            </form>
          </div>
          <div class="form-container sign-in-container">
            <form action="#" role="form">
              <h2>晚上</h2>
              <span class="text-default mb-4">要約白天的話點隔壁喔 </span>
              <div class="form-group mb-0">
                <base-input
                  alternative
                  placeholder="Email"
                  addonLeftIcon="ni ni-email-83"
                ></base-input>
              </div>
              <div class="form-group">
                <base-input
                  alternative
                  placeholder="Password"
                  addonLeftIcon="ni ni-lock-circle-open"
                ></base-input>
              </div>
              <a href="javascript:;">Forgot your password?</a>
              <base-button type="primary" class="mt-3">Sign In</base-button>
            </form>
          </div>
          <div class="overlay-container">
            <div class="overlay">
              <div class="overlay-panel overlay-left">
                <h1 class="text-white">Welcome Back!</h1>
                <p>
                  To keep connected with us please login with your personal info
                </p>
                <base-button type="neutral" size="sm" @click="isActive = false"
                  >Sign In</base-button
                >
              </div>
              <div class="overlay-panel overlay-right">
                <h1 class="text-white">Hi! 您好</h1>
                <p>彈指之前完成預定，不賴吧</p>
                <base-button type="neutral" size="sm" @click="isActive = true"
                  >預約晚上場</base-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="section" style="background-image: url('img/ill/1.svg')">
      <div class="container py-md">
        <div class="row justify-content-between align-items-center">
          <div class="col-lg-6 mb-lg-auto">
            <SeatPicker></SeatPicker>
          </div>
          <div class="col-lg-5 mb-5 mb-lg-0">
            <h1 class="font-weight-light">客製化 UI</h1>
            <p class="lead mt-4">客製化的 UI，滿足您各種預定上的需求</p>
          </div>
        </div>
      </div>
    </div>
    <Contact4></Contact4>
  </div>
</template>
<script>
import EventFeature from './EventFeature';
import Contact4 from './Contact4';
import Boating from './Boating';
import SeatPicker from './SeatPicker';
import OnePageAdvantage from './OnePageAdvantage';
import CallToAction from '../../components/Elements/CallToAction';
import CtaJittery from '../../components/Elements/CallToActions/CtaJittery';

export default {
  data() {
    return {
      isActive: false,
    };
  },
  components: {
    EventFeature,
    Contact4,
    Boating,
    SeatPicker,
    OnePageAdvantage,
    CallToAction,
    CtaJittery,
  },
};
</script>
<style scoped>
.cta {
  background-color: #ecf0f1;
}

/* ===== animation text ===== */
@import url(https://fonts.googleapis.com/css?family=Ubuntu);

svg {
  position: absolute;
  font: 15em Ubuntu;
  letter-spacing: 0.15em;
  width: 100%;
  height: 100%;
  margin-top: 5%;
}

svg text {
  fill: #004567;
  stroke: #000;
  fill-opacity: 1;
  stroke-width: 0;
  stroke-dasharray: 100% 0;
  animation: stroke-offset 6s ease-out;
}

@keyframes stroke-offset {
  0% {
    fill-opacity: 0;
    stroke-width: 0;
    stroke-dasharray: 0% 100%;
  }
  25% {
    stroke-width: 3px;
  }
  50% {
    fill-opacity: 0;
  }
  75% {
    stroke-width: 3px;
  }
  100% {
    stroke-width: 0;
  }
}

/* ===== animation sea ===== */

.sea {
  background: #66ccff;
  height: 100vh;
}

.cloud {
  position: fixed;
  left: 100px;
  height: 100px;
  width: 300px;
  border-bottom: 5px solid white;
  overflow: hidden;
}
.cloud:after {
  position: absolute;
  content: '';
  top: 60px;
  width: 100px;
  height: 75px;
  border-radius: 50%;
  background: white;
}
.cloud:before {
  position: absolute;
  content: '';
  top: 20px;
  left: 60px;
  width: 150px;
  height: 100px;
  border-radius: 50%;
  background: white;
}
.cloud #sA {
  position: absolute;
  width: 120px;
  height: 100px;
  border-radius: 50%;
  left: 182px;
  top: 65px;
  background: white;
}

#cA {
  top: 160px;
  -webkit-transform: scale(1.2);
  -webkit-animation: cloud 25s linear infinite alternate;
}

#cB {
  left: -200px;
  top: 80px;
  -webkit-transform: scale(0.7) scaleX(-1);
  -webkit-animation: cloud 20s linear infinite reverse;
}

#cC {
  top: 265px;
  -webkit-animation: cloud 27s linear infinite reverse;
}

#cD {
  top: 450px;
  -webkit-transform: scale(0.5);
  -webkit-animation: cloud 35s linear infinite alternate;
}

@-webkit-keyframes cloud {
  from {
    left: -50vw;
  }
  to {
    left: 120vw;
  }
}
#foot {
  z-index: 1;
  width: 100%;
  height: 150px;
  position: absolute;
  bottom: 0;
  background: #004567;
}

.boat-two {
  position: absolute;
  width: 25px;
  height: 20px;
  right: 0px;
  bottom: 145px;
  background: #666;
  -webkit-animation: sail 35s linear infinite;
}
.boat-two:before,
.boat-two:after {
  content: '';
  position: absolute;
  border-top: 20px solid #666;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}
.boat-two:after {
  left: -20px;
}
.boat-two:before {
  left: 5px;
}
.boat-two #flag {
  position: absolute;
  width: 3px;
  height: 30px;
  background: black;
  top: -30px;
}
.boat-two #flag:after {
  content: '';
  position: absolute;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 30px solid #eaeaea;
  left: 3px;
  -webkit-animation: wave 1s linear infinite;
}

@-webkit-keyframes wave {
  0%,
  100% {
    -webkit-transform: scaleX(1);
    left: 3px;
  }
  50% {
    -webkit-transform: scaleX(-0.5);
    left: -19px;
  }
}
@-webkit-keyframes sail {
  from {
    right: 0vw;
  }
  to {
    right: 150vw;
  }
}

.bg-default {
  background-color: #ffefd2 !important;
}
</style>

<style>
.footer {
  position: relative;
}
</style>