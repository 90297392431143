<template>
  <header class="header-global">
    <base-nav
      class="navbar-main headroom"
      id="navbar-main"
      :type="navbarType ? navbarType : ''"
      effect="white"
      expand
    >
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
        <img v-if="isPin" src="img/brand/logo.png" alt="logo" />
        <img v-else src="img/brand/white.png" alt="logo" />
      </router-link>

      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <a href="javascript:void(0)">
            <img src="img/brand/blue.png" />
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <!-- <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
        <base-dropdown class="nav-item">
          <a
            role="button"
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
          >
            <i class="ni ni-tablet-button d-lg-none"></i>
            <span class="nav-link-inner--text">網站範例</span>
          </a>
          <router-link to="/one-page" class="dropdown-item">
            <i class="ni ni-lock-circle-open text-muted"></i>
            一頁式網站
          </router-link>
          <router-link to="/account" class="dropdown-item">
            <i class="ni ni-lock-circle-open text-muted"></i>
            品牌形象官網
          </router-link>
          <router-link to="/account" class="dropdown-item">
            <i class="ni ni-lock-circle-open text-muted"></i>
            購物車網站
          </router-link>
          <router-link to="/account" class="dropdown-item">
            <i class="ni ni-lock-circle-open text-muted"></i>
            客製化網站
          </router-link>
        </base-dropdown>

        <base-dropdown class="nav-item">
          <a
            role="button"
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
          >
            <i class="ni ni-tablet-button d-lg-none"></i>
            <span class="nav-link-inner--text">服務項目</span>
          </a>
          <router-link to="/account" class="dropdown-item">
            <i class="ni ni-lock-circle-open text-muted"></i>
            程式開發
          </router-link>
          <router-link to="/account" class="dropdown-item">
            <i class="ni ni-lock-circle-open text-muted"></i>
            UI/UX 設計
          </router-link>
          <router-link to="/account" class="dropdown-item">
            <i class="ni ni-lock-circle-open text-muted"></i>
            手機 APP 開發
          </router-link>
        </base-dropdown>
        <base-dropdown>
          <a
            @click.prevent="go('slide')"
            role="button"
            slot="title"
            href="＃"
            class="nav-link"
            data-toggle="dropdown"
          >
            <i class="ni ni-tablet-button d-lg-none"></i>
            <span class="nav-link-inner--text">滑手機囉</span>
          </a>
        </base-dropdown>
      </ul> -->
    </base-nav>
  </header>
</template>
<script>
import BaseNav from '@/components/BaseNav';
import CloseButton from '@/components/CloseButton';
// import BaseDropdown from '@/components/BaseDropdown';
import Headroom from 'headroom.js';

export default {
  data() {
    return {
      isPin: false,
    };
  },
  methods: {
    go(id) {
      document.querySelector('#' + id).scrollIntoView(true);
    },
  },
  components: {
    BaseNav,
    CloseButton,
    // BaseDropdown,
  },
  props: {
    navbarType: String,
  },
  mounted: function () {
    let self = this;
    let headroom = new Headroom(document.getElementById('navbar-main'), {
      offset: 300,
      tolerance: {
        up: 30,
        down: 30,
      },
      onTop: () => {
        self.isPin = true;
      },
      onNotTop: () => {
        self.isPin = false;
      },
    });
    headroom.init();
  },
};
</script>
<style>
.navbar-main.headroom {
  z-index: 9999;
}

.headroom--not-top .nav-link-inner--text {
  color: #ffffff;
}
</style>
