<template>
  <div class="summerdream">
    <div class="sea">
      <div class="surface"></div>
    </div>
    <div class="ship">
      <div class="rotate">
        <div class="move">
          <div class="body">
            <div class="waves">
              <div class="bodywaves">
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
              </div>
              <div class="oarwaves -left">
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
              </div>
              <div class="oarwaves -right">
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
                <div class="wave">
                  <div class="graphic"></div>
                </div>
              </div>
            </div>
            <div class="base"></div>
            <div class="board -front"></div>
            <div class="board -back"></div>
          </div>
          <div class="oars">
            <div class="oar -left">
              <div class="row -left">
                <div class="depth -left">
                  <div class="graphic -left"></div>
                </div>
              </div>
            </div>
            <div class="oar -right">
              <div class="row -right">
                <div class="depth -right">
                  <div class="graphic -right"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="human">
            <div class="legs">
              <div class="leg -left"></div>
              <div class="leg -right"></div>
            </div>
            <div class="hat"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.summerdream {
  background: #fff;
  height: 500px;
  overflow: hidden;
  display: flex;
  font-family: 'Anton', sans-serif;
  justify-content: center;
  align-items: center;
  perspective: 500px;
}

div {
  transform-style: preserve-3d;
}
/* 
.sea {
  position: absolute;
  left: 0;
  top: 0;
} */

.sea .surface {
  /* position: absolute; */
  height: 400px;
  width: 400px;
  background: #2be3fa;
  overflow: hidden;
  -webkit-animation: surface 13000ms ease-in-out infinite alternate,
    rotateZ 20000ms linear infinite;
  animation: surface 13000ms ease-in-out infinite alternate,
    rotateZ 20000ms linear infinite;
}
.sea .surface .inner {
  width: 600px;
  height: 600px;
  animation: rotateZ 20000ms linear infinite reverse;
}

.fish {
  position: absolute;
  top: 100px;
  left: 200px;
}
.fish .body {
  position: absolute;
  width: 20px;
  height: 5px;
  background: #1971b3;
  border-radius: 100%;
}
.fish .body::before {
  content: '';
  position: absolute;
  top: -3px;
  left: 12px;
  width: 2px;
  height: 5px;
  background: #1971b3;
  border-radius: 100% 100% 0 0;
  transform: rotateZ(-30deg);
}
.fish .body::after {
  content: '';
  position: absolute;
  top: 3px;
  left: 12px;
  width: 2px;
  height: 5px;
  background: #1971b3;
  border-radius: 100% 100% 0 0;
  transform: rotateZ(30deg);
}

.ship {
  position: absolute;
  /* top: 300px;
  left: 300px; */
  filter: drop-shadow(-30px 40px 0 rgba(0, 0, 0, 0.1));
}
.ship .rotate {
  transform: rotateZ(10deg);
  -webkit-animation: ship 30000ms linear infinite alternate;
  animation: ship 30000ms linear infinite alternate;
}
.ship .human {
  position: absolute;
  top: 0;
  left: 40px;
}
.ship .human .hat {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #f0f09e;
  border-radius: 100%;
  -webkit-animation: hat 1000ms ease-in-out infinite alternate;
  animation: hat 1000ms ease-in-out infinite alternate;
}
.ship .human .hat::before {
  content: '';
  position: absolute;
  top: 7px;
  left: 7px;
  width: 16px;
  height: 16px;
  border: 1px solid #d9845d;
  border-radius: 100%;
  box-sizing: border-box;
  transform: translateZ(5px);
  transform-style: preserve-3d;
}
.ship .human .leg {
  position: absolute;
  width: 20px;
  height: 8px;
  background: #0849a3;
  border-radius: 40%;
}
.ship .human .leg::before {
  content: '';
  position: absolute;
  left: -4px;
  width: 5px;
  height: 7px;
  background: #fff;
  border-radius: 40%;
}
.ship .human .leg.-left {
  top: 16px;
  left: -10px;
  transform: rotateZ(-5deg);
}
.ship .human .leg.-left::before {
  top: 1px;
}
.ship .human .leg.-right {
  top: 6px;
  left: -10px;
  transform: rotateZ(5deg);
}
.ship .human .leg.-right::before {
  top: -1px;
}
.ship .body {
  position: relative;
}
.ship .body .base {
  width: 100px;
  height: 30px;
  background: #d9845d;
  border-radius: 45% 45% 45% 45%;
}
.ship .body .base::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 96px;
  height: 26px;
  background: #dead5e;
  border-radius: 100%;
  border-radius: 45% 45% 45% 45%;
}
.ship .body .board {
  position: absolute;
  top: 5px;
  width: 10px;
  height: 20px;
  background: #d9845d;
  border-radius: 2px;
}
.ship .body .board.-front {
  right: 20px;
}
.ship .body .board.-back {
  left: 20px;
}
.ship .body .waves {
  position: absolute;
}
.ship .body .waves .wave {
  position: absolute;
  -webkit-animation: wave 2000ms linear infinite;
  animation: wave 2000ms linear infinite;
}
.ship .body .waves .wave .graphic {
  background: #fff;
  -webkit-animation: surface 2000ms ease-in-out infinite alternate,
    rotateZ 6000ms linear infinite;
  animation: surface 2000ms ease-in-out infinite alternate,
    rotateZ 6000ms linear infinite;
}
.ship .body .waves .bodywaves .wave:nth-child(1) {
  top: 2px;
  left: 43px;
  -webkit-animation-delay: -1781ms;
  animation-delay: -1781ms;
}
.ship .body .waves .bodywaves .wave:nth-child(1) .graphic {
  width: 18px;
  height: 9px;
}
.ship .body .waves .bodywaves .wave:nth-child(2) {
  top: 9px;
  left: 44px;
  -webkit-animation-delay: -373ms;
  animation-delay: -373ms;
}
.ship .body .waves .bodywaves .wave:nth-child(2) .graphic {
  width: 12px;
  height: 17px;
}
.ship .body .waves .bodywaves .wave:nth-child(3) {
  top: 5px;
  left: 60px;
  -webkit-animation-delay: -880ms;
  animation-delay: -880ms;
}
.ship .body .waves .bodywaves .wave:nth-child(3) .graphic {
  width: 14px;
  height: 17px;
}
.ship .body .waves .bodywaves .wave:nth-child(4) {
  top: 14px;
  left: 56px;
  -webkit-animation-delay: -1031ms;
  animation-delay: -1031ms;
}
.ship .body .waves .bodywaves .wave:nth-child(4) .graphic {
  width: 14px;
  height: 17px;
}
.ship .body .waves .bodywaves .wave:nth-child(5) {
  top: 4px;
  left: 54px;
  -webkit-animation-delay: -425ms;
  animation-delay: -425ms;
}
.ship .body .waves .bodywaves .wave:nth-child(5) .graphic {
  width: 14px;
  height: 14px;
}
.ship .body .waves .bodywaves .wave:nth-child(6) {
  top: 2px;
  left: 11px;
  -webkit-animation-delay: -2004ms;
  animation-delay: -2004ms;
}
.ship .body .waves .bodywaves .wave:nth-child(6) .graphic {
  width: 17px;
  height: 14px;
}
.ship .body .waves .bodywaves .wave:nth-child(7) {
  top: 15px;
  left: 53px;
  -webkit-animation-delay: -2503ms;
  animation-delay: -2503ms;
}
.ship .body .waves .bodywaves .wave:nth-child(7) .graphic {
  width: 15px;
  height: 9px;
}
.ship .body .waves .bodywaves .wave:nth-child(8) {
  top: 7px;
  left: 41px;
  -webkit-animation-delay: -2580ms;
  animation-delay: -2580ms;
}
.ship .body .waves .bodywaves .wave:nth-child(8) .graphic {
  width: 14px;
  height: 11px;
}
.ship .body .waves .bodywaves .wave:nth-child(9) {
  top: 10px;
  left: 44px;
  -webkit-animation-delay: -3641ms;
  animation-delay: -3641ms;
}
.ship .body .waves .bodywaves .wave:nth-child(9) .graphic {
  width: 13px;
  height: 17px;
}
.ship .body .waves .bodywaves .wave:nth-child(10) {
  top: 9px;
  left: 45px;
  -webkit-animation-delay: -912ms;
  animation-delay: -912ms;
}
.ship .body .waves .bodywaves .wave:nth-child(10) .graphic {
  width: 13px;
  height: 16px;
}
.ship .body .waves .bodywaves .wave:nth-child(11) {
  top: 15px;
  left: 45px;
  -webkit-animation-delay: -615ms;
  animation-delay: -615ms;
}
.ship .body .waves .bodywaves .wave:nth-child(11) .graphic {
  width: 9px;
  height: 16px;
}
.ship .body .waves .bodywaves .wave:nth-child(12) {
  top: 3px;
  left: 32px;
  -webkit-animation-delay: -3222ms;
  animation-delay: -3222ms;
}
.ship .body .waves .bodywaves .wave:nth-child(12) .graphic {
  width: 15px;
  height: 11px;
}
.ship .body .waves .bodywaves .wave:nth-child(13) {
  top: 2px;
  left: 24px;
  -webkit-animation-delay: -2958ms;
  animation-delay: -2958ms;
}
.ship .body .waves .bodywaves .wave:nth-child(13) .graphic {
  width: 13px;
  height: 15px;
}
.ship .body .waves .bodywaves .wave:nth-child(14) {
  top: 3px;
  left: 45px;
  -webkit-animation-delay: -2483ms;
  animation-delay: -2483ms;
}
.ship .body .waves .bodywaves .wave:nth-child(14) .graphic {
  width: 12px;
  height: 9px;
}
.ship .body .waves .bodywaves .wave:nth-child(15) {
  top: -1px;
  left: 54px;
  -webkit-animation-delay: -2734ms;
  animation-delay: -2734ms;
}
.ship .body .waves .bodywaves .wave:nth-child(15) .graphic {
  width: 18px;
  height: 16px;
}
.ship .body .waves .bodywaves .wave:nth-child(16) {
  top: 3px;
  left: 43px;
  -webkit-animation-delay: -762ms;
  animation-delay: -762ms;
}
.ship .body .waves .bodywaves .wave:nth-child(16) .graphic {
  width: 17px;
  height: 16px;
}
.ship .body .waves .bodywaves .wave:nth-child(17) {
  top: 16px;
  left: 38px;
  -webkit-animation-delay: -800ms;
  animation-delay: -800ms;
}
.ship .body .waves .bodywaves .wave:nth-child(17) .graphic {
  width: 10px;
  height: 10px;
}
.ship .body .waves .bodywaves .wave:nth-child(18) {
  top: 13px;
  left: 48px;
  -webkit-animation-delay: -1246ms;
  animation-delay: -1246ms;
}
.ship .body .waves .bodywaves .wave:nth-child(18) .graphic {
  width: 18px;
  height: 18px;
}
.ship .body .waves .bodywaves .wave:nth-child(19) {
  top: 13px;
  left: 56px;
  -webkit-animation-delay: -2143ms;
  animation-delay: -2143ms;
}
.ship .body .waves .bodywaves .wave:nth-child(19) .graphic {
  width: 18px;
  height: 17px;
}
.ship .body .waves .bodywaves .wave:nth-child(20) {
  top: 11px;
  left: 46px;
  -webkit-animation-delay: -2532ms;
  animation-delay: -2532ms;
}
.ship .body .waves .bodywaves .wave:nth-child(20) .graphic {
  width: 11px;
  height: 14px;
}
.ship .body .waves .bodywaves .wave:nth-child(21) {
  top: 0px;
  left: 14px;
  -webkit-animation-delay: -3929ms;
  animation-delay: -3929ms;
}
.ship .body .waves .bodywaves .wave:nth-child(21) .graphic {
  width: 15px;
  height: 9px;
}
.ship .body .waves .bodywaves .wave:nth-child(22) {
  top: 9px;
  left: 35px;
  -webkit-animation-delay: -1806ms;
  animation-delay: -1806ms;
}
.ship .body .waves .bodywaves .wave:nth-child(22) .graphic {
  width: 18px;
  height: 11px;
}
.ship .body .waves .bodywaves .wave:nth-child(23) {
  top: 0px;
  left: 59px;
  -webkit-animation-delay: -2881ms;
  animation-delay: -2881ms;
}
.ship .body .waves .bodywaves .wave:nth-child(23) .graphic {
  width: 14px;
  height: 9px;
}
.ship .body .waves .bodywaves .wave:nth-child(24) {
  top: -2px;
  left: 31px;
  -webkit-animation-delay: -3324ms;
  animation-delay: -3324ms;
}
.ship .body .waves .bodywaves .wave:nth-child(24) .graphic {
  width: 10px;
  height: 13px;
}
.ship .body .waves .bodywaves .wave:nth-child(25) {
  top: 13px;
  left: 28px;
  -webkit-animation-delay: -203ms;
  animation-delay: -203ms;
}
.ship .body .waves .bodywaves .wave:nth-child(25) .graphic {
  width: 16px;
  height: 11px;
}
.ship .body .waves .bodywaves .wave:nth-child(26) {
  top: 16px;
  left: 25px;
  -webkit-animation-delay: -2402ms;
  animation-delay: -2402ms;
}
.ship .body .waves .bodywaves .wave:nth-child(26) .graphic {
  width: 11px;
  height: 12px;
}
.ship .body .waves .bodywaves .wave:nth-child(27) {
  top: 4px;
  left: 29px;
  -webkit-animation-delay: -1159ms;
  animation-delay: -1159ms;
}
.ship .body .waves .bodywaves .wave:nth-child(27) .graphic {
  width: 18px;
  height: 12px;
}
.ship .body .waves .bodywaves .wave:nth-child(28) {
  top: 3px;
  left: 22px;
  -webkit-animation-delay: -2766ms;
  animation-delay: -2766ms;
}
.ship .body .waves .bodywaves .wave:nth-child(28) .graphic {
  width: 15px;
  height: 9px;
}
.ship .body .waves .bodywaves .wave:nth-child(29) {
  top: 4px;
  left: 42px;
  -webkit-animation-delay: -2728ms;
  animation-delay: -2728ms;
}
.ship .body .waves .bodywaves .wave:nth-child(29) .graphic {
  width: 16px;
  height: 15px;
}
.ship .body .waves .bodywaves .wave:nth-child(30) {
  top: 4px;
  left: 58px;
  -webkit-animation-delay: -2818ms;
  animation-delay: -2818ms;
}
.ship .body .waves .bodywaves .wave:nth-child(30) .graphic {
  width: 10px;
  height: 10px;
}
.ship .body .waves .bodywaves .wave:nth-child(31) {
  top: 5px;
  left: 43px;
  -webkit-animation-delay: -1734ms;
  animation-delay: -1734ms;
}
.ship .body .waves .bodywaves .wave:nth-child(31) .graphic {
  width: 12px;
  height: 9px;
}
.ship .body .waves .bodywaves .wave:nth-child(32) {
  top: 17px;
  left: 44px;
  -webkit-animation-delay: -2168ms;
  animation-delay: -2168ms;
}
.ship .body .waves .bodywaves .wave:nth-child(32) .graphic {
  width: 11px;
  height: 11px;
}
.ship .body .waves .bodywaves .wave:nth-child(33) {
  top: 0px;
  left: 46px;
  -webkit-animation-delay: -3147ms;
  animation-delay: -3147ms;
}
.ship .body .waves .bodywaves .wave:nth-child(33) .graphic {
  width: 13px;
  height: 10px;
}
.ship .body .waves .bodywaves .wave:nth-child(34) {
  top: 10px;
  left: 34px;
  -webkit-animation-delay: -3184ms;
  animation-delay: -3184ms;
}
.ship .body .waves .bodywaves .wave:nth-child(34) .graphic {
  width: 18px;
  height: 10px;
}
.ship .body .waves .bodywaves .wave:nth-child(35) {
  top: 17px;
  left: 55px;
  -webkit-animation-delay: -988ms;
  animation-delay: -988ms;
}
.ship .body .waves .bodywaves .wave:nth-child(35) .graphic {
  width: 18px;
  height: 14px;
}
.ship .body .waves .bodywaves .wave:nth-child(36) {
  top: 15px;
  left: 24px;
  -webkit-animation-delay: -1123ms;
  animation-delay: -1123ms;
}
.ship .body .waves .bodywaves .wave:nth-child(36) .graphic {
  width: 12px;
  height: 16px;
}
.ship .body .waves .bodywaves .wave:nth-child(37) {
  top: 10px;
  left: 26px;
  -webkit-animation-delay: -1549ms;
  animation-delay: -1549ms;
}
.ship .body .waves .bodywaves .wave:nth-child(37) .graphic {
  width: 13px;
  height: 15px;
}
.ship .body .waves .bodywaves .wave:nth-child(38) {
  top: 4px;
  left: 52px;
  -webkit-animation-delay: -2581ms;
  animation-delay: -2581ms;
}
.ship .body .waves .bodywaves .wave:nth-child(38) .graphic {
  width: 13px;
  height: 17px;
}
.ship .body .waves .bodywaves .wave:nth-child(39) {
  top: 8px;
  left: 24px;
  -webkit-animation-delay: -3259ms;
  animation-delay: -3259ms;
}
.ship .body .waves .bodywaves .wave:nth-child(39) .graphic {
  width: 15px;
  height: 16px;
}
.ship .body .waves .bodywaves .wave:nth-child(40) {
  top: 16px;
  left: 58px;
  -webkit-animation-delay: -3449ms;
  animation-delay: -3449ms;
}
.ship .body .waves .bodywaves .wave:nth-child(40) .graphic {
  width: 18px;
  height: 9px;
}
.ship .body .waves .bodywaves .wave:nth-child(41) {
  top: 11px;
  left: 49px;
  -webkit-animation-delay: -3308ms;
  animation-delay: -3308ms;
}
.ship .body .waves .bodywaves .wave:nth-child(41) .graphic {
  width: 9px;
  height: 10px;
}
.ship .body .waves .bodywaves .wave:nth-child(42) {
  top: 0px;
  left: 39px;
  -webkit-animation-delay: -3995ms;
  animation-delay: -3995ms;
}
.ship .body .waves .bodywaves .wave:nth-child(42) .graphic {
  width: 11px;
  height: 9px;
}
.ship .body .waves .bodywaves .wave:nth-child(43) {
  top: 10px;
  left: 23px;
  -webkit-animation-delay: -781ms;
  animation-delay: -781ms;
}
.ship .body .waves .bodywaves .wave:nth-child(43) .graphic {
  width: 11px;
  height: 10px;
}
.ship .body .waves .bodywaves .wave:nth-child(44) {
  top: 9px;
  left: 26px;
  -webkit-animation-delay: -314ms;
  animation-delay: -314ms;
}
.ship .body .waves .bodywaves .wave:nth-child(44) .graphic {
  width: 11px;
  height: 10px;
}
.ship .body .waves .bodywaves .wave:nth-child(45) {
  top: 4px;
  left: 42px;
  -webkit-animation-delay: -1681ms;
  animation-delay: -1681ms;
}
.ship .body .waves .bodywaves .wave:nth-child(45) .graphic {
  width: 9px;
  height: 18px;
}
.ship .body .waves .bodywaves .wave:nth-child(46) {
  top: 16px;
  left: 51px;
  -webkit-animation-delay: -2141ms;
  animation-delay: -2141ms;
}
.ship .body .waves .bodywaves .wave:nth-child(46) .graphic {
  width: 18px;
  height: 17px;
}
.ship .body .waves .bodywaves .wave:nth-child(47) {
  top: 16px;
  left: 42px;
  -webkit-animation-delay: -500ms;
  animation-delay: -500ms;
}
.ship .body .waves .bodywaves .wave:nth-child(47) .graphic {
  width: 14px;
  height: 13px;
}
.ship .body .waves .bodywaves .wave:nth-child(48) {
  top: 15px;
  left: 60px;
  -webkit-animation-delay: -476ms;
  animation-delay: -476ms;
}
.ship .body .waves .bodywaves .wave:nth-child(48) .graphic {
  width: 12px;
  height: 9px;
}
.ship .body .waves .bodywaves .wave:nth-child(49) {
  top: 11px;
  left: 49px;
  -webkit-animation-delay: -3867ms;
  animation-delay: -3867ms;
}
.ship .body .waves .bodywaves .wave:nth-child(49) .graphic {
  width: 14px;
  height: 12px;
}
.ship .body .waves .bodywaves .wave:nth-child(50) {
  top: 8px;
  left: 39px;
  -webkit-animation-delay: -1029ms;
  animation-delay: -1029ms;
}
.ship .body .waves .bodywaves .wave:nth-child(50) .graphic {
  width: 12px;
  height: 13px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(1) {
  top: -24px;
  left: 47px;
  -webkit-animation-delay: -1413ms;
  animation-delay: -1413ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(1) .graphic {
  width: 7px;
  height: 6px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(2) {
  top: -18px;
  left: 47px;
  -webkit-animation-delay: -1586ms;
  animation-delay: -1586ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(2) .graphic {
  width: 10px;
  height: 8px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(3) {
  top: -20px;
  left: 50px;
  -webkit-animation-delay: -1212ms;
  animation-delay: -1212ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(3) .graphic {
  width: 8px;
  height: 6px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(4) {
  top: -27px;
  left: 46px;
  -webkit-animation-delay: -1012ms;
  animation-delay: -1012ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(4) .graphic {
  width: 9px;
  height: 8px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(5) {
  top: -21px;
  left: 50px;
  -webkit-animation-delay: -1728ms;
  animation-delay: -1728ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(5) .graphic {
  width: 10px;
  height: 8px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(6) {
  top: -10px;
  left: 48px;
  -webkit-animation-delay: -1569ms;
  animation-delay: -1569ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(6) .graphic {
  width: 6px;
  height: 10px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(7) {
  top: -27px;
  left: 47px;
  -webkit-animation-delay: -1157ms;
  animation-delay: -1157ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(7) .graphic {
  width: 6px;
  height: 10px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(8) {
  top: -26px;
  left: 46px;
  -webkit-animation-delay: -1483ms;
  animation-delay: -1483ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(8) .graphic {
  width: 9px;
  height: 8px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(9) {
  top: -16px;
  left: 45px;
  -webkit-animation-delay: -1653ms;
  animation-delay: -1653ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(9) .graphic {
  width: 8px;
  height: 10px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(10) {
  top: -26px;
  left: 44px;
  -webkit-animation-delay: -1549ms;
  animation-delay: -1549ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(10) .graphic {
  width: 7px;
  height: 7px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(11) {
  top: -23px;
  left: 48px;
  -webkit-animation-delay: -1076ms;
  animation-delay: -1076ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(11) .graphic {
  width: 8px;
  height: 7px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(12) {
  top: -26px;
  left: 45px;
  -webkit-animation-delay: -1165ms;
  animation-delay: -1165ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(12) .graphic {
  width: 6px;
  height: 7px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(13) {
  top: -24px;
  left: 43px;
  -webkit-animation-delay: -1179ms;
  animation-delay: -1179ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(13) .graphic {
  width: 6px;
  height: 10px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(14) {
  top: -26px;
  left: 50px;
  -webkit-animation-delay: -1216ms;
  animation-delay: -1216ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(14) .graphic {
  width: 9px;
  height: 7px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(15) {
  top: -12px;
  left: 50px;
  -webkit-animation-delay: -1216ms;
  animation-delay: -1216ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(15) .graphic {
  width: 8px;
  height: 8px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(16) {
  top: -12px;
  left: 43px;
  -webkit-animation-delay: -1070ms;
  animation-delay: -1070ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(16) .graphic {
  width: 9px;
  height: 8px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(17) {
  top: -18px;
  left: 42px;
  -webkit-animation-delay: -1753ms;
  animation-delay: -1753ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(17) .graphic {
  width: 9px;
  height: 6px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(18) {
  top: -24px;
  left: 46px;
  -webkit-animation-delay: -1458ms;
  animation-delay: -1458ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(18) .graphic {
  width: 8px;
  height: 7px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(19) {
  top: -14px;
  left: 45px;
  -webkit-animation-delay: -1553ms;
  animation-delay: -1553ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(19) .graphic {
  width: 10px;
  height: 10px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(20) {
  top: -11px;
  left: 45px;
  -webkit-animation-delay: -1117ms;
  animation-delay: -1117ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(20) .graphic {
  width: 9px;
  height: 6px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(21) {
  top: -29px;
  left: 43px;
  -webkit-animation-delay: -1730ms;
  animation-delay: -1730ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(21) .graphic {
  width: 7px;
  height: 7px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(22) {
  top: -13px;
  left: 42px;
  -webkit-animation-delay: -1269ms;
  animation-delay: -1269ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(22) .graphic {
  width: 8px;
  height: 7px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(23) {
  top: -10px;
  left: 44px;
  -webkit-animation-delay: -1116ms;
  animation-delay: -1116ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(23) .graphic {
  width: 9px;
  height: 9px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(24) {
  top: -14px;
  left: 47px;
  -webkit-animation-delay: -1253ms;
  animation-delay: -1253ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(24) .graphic {
  width: 7px;
  height: 10px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(25) {
  top: -14px;
  left: 50px;
  -webkit-animation-delay: -1580ms;
  animation-delay: -1580ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(25) .graphic {
  width: 8px;
  height: 6px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(26) {
  top: -18px;
  left: 43px;
  -webkit-animation-delay: -1012ms;
  animation-delay: -1012ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(26) .graphic {
  width: 8px;
  height: 9px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(27) {
  top: -18px;
  left: 41px;
  -webkit-animation-delay: -1630ms;
  animation-delay: -1630ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(27) .graphic {
  width: 9px;
  height: 10px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(28) {
  top: -27px;
  left: 41px;
  -webkit-animation-delay: -1654ms;
  animation-delay: -1654ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(28) .graphic {
  width: 8px;
  height: 7px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(29) {
  top: -20px;
  left: 50px;
  -webkit-animation-delay: -1386ms;
  animation-delay: -1386ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(29) .graphic {
  width: 8px;
  height: 8px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(30) {
  top: -19px;
  left: 47px;
  -webkit-animation-delay: -1366ms;
  animation-delay: -1366ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(30) .graphic {
  width: 8px;
  height: 6px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(31) {
  top: -26px;
  left: 42px;
  -webkit-animation-delay: -1344ms;
  animation-delay: -1344ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(31) .graphic {
  width: 6px;
  height: 6px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(32) {
  top: -17px;
  left: 41px;
  -webkit-animation-delay: -1240ms;
  animation-delay: -1240ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(32) .graphic {
  width: 7px;
  height: 9px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(33) {
  top: -15px;
  left: 47px;
  -webkit-animation-delay: -1703ms;
  animation-delay: -1703ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(33) .graphic {
  width: 8px;
  height: 7px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(34) {
  top: -14px;
  left: 41px;
  -webkit-animation-delay: -1169ms;
  animation-delay: -1169ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(34) .graphic {
  width: 8px;
  height: 8px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(35) {
  top: -15px;
  left: 43px;
  -webkit-animation-delay: -1168ms;
  animation-delay: -1168ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(35) .graphic {
  width: 10px;
  height: 6px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(36) {
  top: -13px;
  left: 48px;
  -webkit-animation-delay: -1159ms;
  animation-delay: -1159ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(36) .graphic {
  width: 6px;
  height: 6px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(37) {
  top: -11px;
  left: 42px;
  -webkit-animation-delay: -1470ms;
  animation-delay: -1470ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(37) .graphic {
  width: 10px;
  height: 9px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(38) {
  top: -28px;
  left: 48px;
  -webkit-animation-delay: -1628ms;
  animation-delay: -1628ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(38) .graphic {
  width: 8px;
  height: 8px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(39) {
  top: -15px;
  left: 45px;
  -webkit-animation-delay: -1685ms;
  animation-delay: -1685ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(39) .graphic {
  width: 9px;
  height: 6px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(40) {
  top: -22px;
  left: 48px;
  -webkit-animation-delay: -1302ms;
  animation-delay: -1302ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(40) .graphic {
  width: 6px;
  height: 6px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(41) {
  top: -19px;
  left: 41px;
  -webkit-animation-delay: -1521ms;
  animation-delay: -1521ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(41) .graphic {
  width: 6px;
  height: 10px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(42) {
  top: -24px;
  left: 50px;
  -webkit-animation-delay: -1223ms;
  animation-delay: -1223ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(42) .graphic {
  width: 7px;
  height: 7px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(43) {
  top: -28px;
  left: 46px;
  -webkit-animation-delay: -1125ms;
  animation-delay: -1125ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(43) .graphic {
  width: 7px;
  height: 9px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(44) {
  top: -17px;
  left: 41px;
  -webkit-animation-delay: -1795ms;
  animation-delay: -1795ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(44) .graphic {
  width: 7px;
  height: 10px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(45) {
  top: -11px;
  left: 44px;
  -webkit-animation-delay: -1249ms;
  animation-delay: -1249ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(45) .graphic {
  width: 10px;
  height: 7px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(46) {
  top: -15px;
  left: 44px;
  -webkit-animation-delay: -1517ms;
  animation-delay: -1517ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(46) .graphic {
  width: 6px;
  height: 8px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(47) {
  top: -18px;
  left: 44px;
  -webkit-animation-delay: -1293ms;
  animation-delay: -1293ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(47) .graphic {
  width: 9px;
  height: 10px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(48) {
  top: -26px;
  left: 47px;
  -webkit-animation-delay: -1167ms;
  animation-delay: -1167ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(48) .graphic {
  width: 7px;
  height: 7px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(49) {
  top: -25px;
  left: 49px;
  -webkit-animation-delay: -1146ms;
  animation-delay: -1146ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(49) .graphic {
  width: 6px;
  height: 6px;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(50) {
  top: -29px;
  left: 46px;
  -webkit-animation-delay: -1486ms;
  animation-delay: -1486ms;
}
.ship .body .waves .oarwaves.-left .wave:nth-child(50) .graphic {
  width: 10px;
  height: 6px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(1) {
  top: 43px;
  left: 47px;
  -webkit-animation-delay: -1438ms;
  animation-delay: -1438ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(1) .graphic {
  width: 9px;
  height: 9px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(2) {
  top: 43px;
  left: 48px;
  -webkit-animation-delay: -1278ms;
  animation-delay: -1278ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(2) .graphic {
  width: 8px;
  height: 7px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(3) {
  top: 44px;
  left: 47px;
  -webkit-animation-delay: -1020ms;
  animation-delay: -1020ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(3) .graphic {
  width: 6px;
  height: 9px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(4) {
  top: 45px;
  left: 48px;
  -webkit-animation-delay: -1092ms;
  animation-delay: -1092ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(4) .graphic {
  width: 6px;
  height: 7px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(5) {
  top: 37px;
  left: 50px;
  -webkit-animation-delay: -1216ms;
  animation-delay: -1216ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(5) .graphic {
  width: 7px;
  height: 8px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(6) {
  top: 38px;
  left: 48px;
  -webkit-animation-delay: -1256ms;
  animation-delay: -1256ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(6) .graphic {
  width: 9px;
  height: 10px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(7) {
  top: 42px;
  left: 42px;
  -webkit-animation-delay: -1274ms;
  animation-delay: -1274ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(7) .graphic {
  width: 7px;
  height: 9px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(8) {
  top: 47px;
  left: 44px;
  -webkit-animation-delay: -1737ms;
  animation-delay: -1737ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(8) .graphic {
  width: 10px;
  height: 10px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(9) {
  top: 36px;
  left: 43px;
  -webkit-animation-delay: -1145ms;
  animation-delay: -1145ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(9) .graphic {
  width: 9px;
  height: 8px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(10) {
  top: 53px;
  left: 48px;
  -webkit-animation-delay: -1418ms;
  animation-delay: -1418ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(10) .graphic {
  width: 9px;
  height: 9px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(11) {
  top: 42px;
  left: 42px;
  -webkit-animation-delay: -1427ms;
  animation-delay: -1427ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(11) .graphic {
  width: 10px;
  height: 7px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(12) {
  top: 41px;
  left: 46px;
  -webkit-animation-delay: -1202ms;
  animation-delay: -1202ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(12) .graphic {
  width: 10px;
  height: 8px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(13) {
  top: 41px;
  left: 49px;
  -webkit-animation-delay: -1238ms;
  animation-delay: -1238ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(13) .graphic {
  width: 9px;
  height: 7px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(14) {
  top: 53px;
  left: 48px;
  -webkit-animation-delay: -1092ms;
  animation-delay: -1092ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(14) .graphic {
  width: 6px;
  height: 7px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(15) {
  top: 39px;
  left: 48px;
  -webkit-animation-delay: -1170ms;
  animation-delay: -1170ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(15) .graphic {
  width: 8px;
  height: 7px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(16) {
  top: 46px;
  left: 41px;
  -webkit-animation-delay: -1092ms;
  animation-delay: -1092ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(16) .graphic {
  width: 6px;
  height: 6px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(17) {
  top: 49px;
  left: 43px;
  -webkit-animation-delay: -1515ms;
  animation-delay: -1515ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(17) .graphic {
  width: 6px;
  height: 10px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(18) {
  top: 42px;
  left: 49px;
  -webkit-animation-delay: -1728ms;
  animation-delay: -1728ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(18) .graphic {
  width: 6px;
  height: 6px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(19) {
  top: 40px;
  left: 46px;
  -webkit-animation-delay: -1302ms;
  animation-delay: -1302ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(19) .graphic {
  width: 10px;
  height: 8px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(20) {
  top: 43px;
  left: 43px;
  -webkit-animation-delay: -1536ms;
  animation-delay: -1536ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(20) .graphic {
  width: 7px;
  height: 7px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(21) {
  top: 40px;
  left: 48px;
  -webkit-animation-delay: -1001ms;
  animation-delay: -1001ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(21) .graphic {
  width: 10px;
  height: 6px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(22) {
  top: 42px;
  left: 48px;
  -webkit-animation-delay: -1416ms;
  animation-delay: -1416ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(22) .graphic {
  width: 6px;
  height: 10px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(23) {
  top: 40px;
  left: 49px;
  -webkit-animation-delay: -1500ms;
  animation-delay: -1500ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(23) .graphic {
  width: 7px;
  height: 9px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(24) {
  top: 55px;
  left: 47px;
  -webkit-animation-delay: -1625ms;
  animation-delay: -1625ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(24) .graphic {
  width: 6px;
  height: 8px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(25) {
  top: 45px;
  left: 42px;
  -webkit-animation-delay: -1284ms;
  animation-delay: -1284ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(25) .graphic {
  width: 9px;
  height: 7px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(26) {
  top: 55px;
  left: 42px;
  -webkit-animation-delay: -1631ms;
  animation-delay: -1631ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(26) .graphic {
  width: 6px;
  height: 8px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(27) {
  top: 42px;
  left: 41px;
  -webkit-animation-delay: -1220ms;
  animation-delay: -1220ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(27) .graphic {
  width: 7px;
  height: 8px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(28) {
  top: 42px;
  left: 49px;
  -webkit-animation-delay: -1099ms;
  animation-delay: -1099ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(28) .graphic {
  width: 8px;
  height: 7px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(29) {
  top: 38px;
  left: 49px;
  -webkit-animation-delay: -1258ms;
  animation-delay: -1258ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(29) .graphic {
  width: 9px;
  height: 6px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(30) {
  top: 47px;
  left: 48px;
  -webkit-animation-delay: -1541ms;
  animation-delay: -1541ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(30) .graphic {
  width: 9px;
  height: 7px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(31) {
  top: 39px;
  left: 50px;
  -webkit-animation-delay: -1278ms;
  animation-delay: -1278ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(31) .graphic {
  width: 7px;
  height: 6px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(32) {
  top: 48px;
  left: 42px;
  -webkit-animation-delay: -1449ms;
  animation-delay: -1449ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(32) .graphic {
  width: 8px;
  height: 6px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(33) {
  top: 47px;
  left: 43px;
  -webkit-animation-delay: -1500ms;
  animation-delay: -1500ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(33) .graphic {
  width: 7px;
  height: 6px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(34) {
  top: 36px;
  left: 47px;
  -webkit-animation-delay: -1184ms;
  animation-delay: -1184ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(34) .graphic {
  width: 9px;
  height: 10px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(35) {
  top: 37px;
  left: 44px;
  -webkit-animation-delay: -1274ms;
  animation-delay: -1274ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(35) .graphic {
  width: 6px;
  height: 10px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(36) {
  top: 55px;
  left: 49px;
  -webkit-animation-delay: -1377ms;
  animation-delay: -1377ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(36) .graphic {
  width: 6px;
  height: 10px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(37) {
  top: 40px;
  left: 43px;
  -webkit-animation-delay: -1035ms;
  animation-delay: -1035ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(37) .graphic {
  width: 6px;
  height: 6px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(38) {
  top: 38px;
  left: 49px;
  -webkit-animation-delay: -1374ms;
  animation-delay: -1374ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(38) .graphic {
  width: 6px;
  height: 9px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(39) {
  top: 43px;
  left: 48px;
  -webkit-animation-delay: -1395ms;
  animation-delay: -1395ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(39) .graphic {
  width: 8px;
  height: 6px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(40) {
  top: 36px;
  left: 50px;
  -webkit-animation-delay: -1682ms;
  animation-delay: -1682ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(40) .graphic {
  width: 6px;
  height: 8px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(41) {
  top: 53px;
  left: 45px;
  -webkit-animation-delay: -1636ms;
  animation-delay: -1636ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(41) .graphic {
  width: 9px;
  height: 8px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(42) {
  top: 42px;
  left: 46px;
  -webkit-animation-delay: -1125ms;
  animation-delay: -1125ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(42) .graphic {
  width: 7px;
  height: 7px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(43) {
  top: 55px;
  left: 48px;
  -webkit-animation-delay: -1786ms;
  animation-delay: -1786ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(43) .graphic {
  width: 7px;
  height: 7px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(44) {
  top: 39px;
  left: 46px;
  -webkit-animation-delay: -1551ms;
  animation-delay: -1551ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(44) .graphic {
  width: 9px;
  height: 8px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(45) {
  top: 47px;
  left: 42px;
  -webkit-animation-delay: -1262ms;
  animation-delay: -1262ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(45) .graphic {
  width: 10px;
  height: 8px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(46) {
  top: 52px;
  left: 46px;
  -webkit-animation-delay: -1161ms;
  animation-delay: -1161ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(46) .graphic {
  width: 9px;
  height: 8px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(47) {
  top: 42px;
  left: 45px;
  -webkit-animation-delay: -1483ms;
  animation-delay: -1483ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(47) .graphic {
  width: 7px;
  height: 10px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(48) {
  top: 42px;
  left: 41px;
  -webkit-animation-delay: -1431ms;
  animation-delay: -1431ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(48) .graphic {
  width: 10px;
  height: 10px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(49) {
  top: 43px;
  left: 50px;
  -webkit-animation-delay: -1447ms;
  animation-delay: -1447ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(49) .graphic {
  width: 6px;
  height: 7px;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(50) {
  top: 45px;
  left: 49px;
  -webkit-animation-delay: -1239ms;
  animation-delay: -1239ms;
}
.ship .body .waves .oarwaves.-right .wave:nth-child(50) .graphic {
  width: 6px;
  height: 9px;
}
.ship .oars {
  position: absolute;
  top: -30px;
  left: 50px;
  transform: translateZ(10px);
}
.ship .oars .graphic {
  position: relative;
  width: 3px;
  height: 40px;
  background: #d9845d;
  -webkit-animation: oarGraphic 1000ms ease-in-out infinite alternate;
  animation: oarGraphic 1000ms ease-in-out infinite alternate;
}
.ship .oars .graphic::before {
  content: '';
  position: absolute;
  top: 0;
  left: -3px;
  width: 9px;
  height: 15px;
  background: #d9845d;
  border-radius: 5px 5px 100% 100%;
}
.ship .oars .oar {
  position: absolute;
  top: 0;
  left: 50%;
}
.ship .oars .oar.-right {
  transform-origin: 50% 45px;
  transform: scaleY(-1);
}
.ship .oars .row {
  position: absolute;
  -webkit-animation: row 1000ms ease-in-out infinite alternate;
  animation: row 1000ms ease-in-out infinite alternate;
  transform-origin: 50% 35px;
}
.ship .oars .depth {
  -webkit-animation: depth 1000ms ease-in-out infinite alternate;
  animation: depth 1000ms ease-in-out infinite alternate;
  transform-origin: 50% 35px;
  -webkit-animation-delay: -500ms;
  animation-delay: -500ms;
}

@-webkit-keyframes surface {
  0% {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
  }
  25% {
    border-radius: 65% 35% 51% 49%/49% 52% 48% 51%;
  }
  50% {
    border-radius: 42% 58% 28% 72%/65% 34% 66% 35%;
  }
  75% {
    border-radius: 34% 66% 63% 37%/77% 38% 62% 23%;
  }
  100% {
    border-radius: 24% 76% 72% 28%/53% 68% 32% 47%;
  }
}

@keyframes surface {
  0% {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
  }
  25% {
    border-radius: 65% 35% 51% 49%/49% 52% 48% 51%;
  }
  50% {
    border-radius: 42% 58% 28% 72%/65% 34% 66% 35%;
  }
  75% {
    border-radius: 34% 66% 63% 37%/77% 38% 62% 23%;
  }
  100% {
    border-radius: 24% 76% 72% 28%/53% 68% 32% 47%;
  }
}
@-webkit-keyframes rotateZ {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
@keyframes rotateZ {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
@-webkit-keyframes ship {
  0% {
    transform: rotateZ(20deg);
  }
  100% {
    transform: rotateZ(-20deg);
  }
}
@keyframes ship {
  0% {
    transform: rotateZ(20deg);
  }
  100% {
    transform: rotateZ(-20deg);
  }
}
@-webkit-keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(5px);
  }
}
@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(5px);
  }
}
@-webkit-keyframes wave {
  0% {
    transform: translateX(0) scale(0);
  }
  10% {
    transform: translateX(-10px) scale(1);
  }
  100% {
    transform: translateX(-130px) scale(0);
  }
}
@keyframes wave {
  0% {
    transform: translateX(0) scale(0);
  }
  10% {
    transform: translateX(-10px) scale(1);
  }
  100% {
    transform: translateX(-130px) scale(0);
  }
}
@-webkit-keyframes depth {
  0% {
    transform: rotateX(-20deg);
  }
  100% {
    transform: rotateX(50deg);
  }
}
@keyframes depth {
  0% {
    transform: rotateX(-20deg);
  }
  100% {
    transform: rotateX(50deg);
  }
}
@-webkit-keyframes row {
  0% {
    transform: rotateZ(20deg);
  }
  100% {
    transform: rotateZ(-50deg);
  }
}
@keyframes row {
  0% {
    transform: rotateZ(20deg);
  }
  100% {
    transform: rotateZ(-50deg);
  }
}
@-webkit-keyframes oarGraphic {
  0% {
    transform: rotateY(-60deg);
  }
  100% {
    transform: rotateY(-110deg);
  }
}
@keyframes oarGraphic {
  0% {
    transform: rotateY(-60deg);
  }
  100% {
    transform: rotateY(-110deg);
  }
}
@-webkit-keyframes hat {
  0% {
    transform: translateZ(30px) translateX(-5px) rotateY(-20deg);
  }
  100% {
    transform: translateZ(30px) translateX(0) rotateY(20deg);
  }
}
@keyframes hat {
  0% {
    transform: translateZ(30px) translateX(-5px) rotateY(-20deg);
  }
  100% {
    transform: translateZ(30px) translateX(0) rotateY(20deg);
  }
}
</style>